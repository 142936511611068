<template>
  <v-card
    :class="{ 'mobile-login': !isDesktop, 'd-flex flex-column align-center': isSelectAccount }"
    class="ma-auto pa-md-10 pa-0 h-100-mobile w-100"
    :max-width="isSelectAccount ? '460px' : '660px'"
  >
    <div class="d-flex d-md-none align-center justify-center w-100 pa-5" style="background-color: #00084B">
      <v-img
        position="left center"
        :src="require('@/assets/logo.svg')"
        height="44px"
        max-width="167px"
        contain
      ></v-img>
    </div>
    <template v-if="!loadingSelectedAccount">
      <v-card-title class="pa-0 pa-4 px-md-0 pb-8">
        <h2 class="font-weight-semi-bold fz-28 dark-text w-max">{{ isSelectAccount ? 'Select Account' : 'Sign In' }}</h2>
      </v-card-title>
      <v-card-text class="pa-0 px-4 px-md-0" v-if="!isSelectAccount">
        <v-form v-model="formValidate" lazy-validation class="h-100-mobile">
          <v-row>
            <v-col
                cols="12"
                md="6"
                class="py-0"
            >
              <label class="fz-14 mb-1 d-block dark-text">Email</label>
              <v-text-field
                  solo
                  outlined
                  type="email"
                  v-model="form.email"
                  :rules="emailRules"
                  :height="isDesktop ? '36px' : '48px'"
                  dense
                  required
                  class="login-field"
                  @keypress.enter.prevent="focusPassword"
              ></v-text-field>
              <v-checkbox
                v-model="form.rememberMe"
                label="Remember me"
                class="mt-0"
              />
            </v-col>

            <v-col
                cols="12"
                md="6"
                class="py-0"
            >
              <label class="fz-14 mb-1 d-block dark-text">Password</label>
              <v-text-field
                  v-model="form.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  :height="isDesktop ? '36px' : '48px'"
                  solo
                  outlined
                  ref="password"
                  dense
                  hide-details="auto"
                  class="login-field"
                  @keypress.enter.prevent="loginUser(null)"
                  @click:append="showPassword = !showPassword"
              ></v-text-field>
              <router-link to="/recovery" class="text-decoration-none d-block mt-1">
                <span class="fz-14 grey--text text--darken-1">Forgot password?</span>
              </router-link>
            </v-col>
          </v-row>
          <!-- <v-row v-if="isDesktop" class="mt-0">
            <v-col cols="4" class="pb-0">
              <v-checkbox
                  v-model="form.rememberMe"
                  label="Remember me"
                  class="mt-0"
              />
            </v-col>
          </v-row> -->
          <v-row
              class="position-absolute-mobile mt-md-7 align-baseline"
              :class="!isDesktop ? 'no-gutters' : ''">
            <v-col
                order-md="1"
                order="2"
                cols="12"
                md="6"
                class="d-flex align-center justify-center justify-md-start regular-text--text px-0"
            >
              <span style="white-space: pre">Don’t have an account?</span>
              <v-btn
                  to="/registration"
                  :color="!isDesktop ? 'primary' : ''"
                  :text="!isDesktop"
                  class="ml-3"
                  style="text-transform: capitalize;"
                  :class="!isDesktop ? 'font-weight-bold' : 'grey-button regular-text--text'"
              >
                Sign Up
              </v-btn>
            </v-col>

            <v-col
                order-md="2"
                order="1"
                cols="12"
                md="6"
                class="d-flex flex-row justify-end pb-0 px-4"
            >
              <v-btn
                  :disabled="!formValidate"
                  :class="!isDesktop ? 'w-100' : ''"
                  class="violet-button py-3 px-6"
                  @click="loginUser(null)"
                  :loading="loading"
                  :height="isDesktop ? '35px' : '44px'"
              >Sign In
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <template v-else>
<!--        <v-card-text v-for="(user, index) in userList" :key="index" class="d-flex flex-column justify-center align-center text-left">-->
<!--          <v-btn text @click="loginUser(user)" style="width: 100%; max-width: 260px;">{{ user.web_url }}</v-btn>-->
<!--        </v-card-text>-->

        <v-list class="py-0">
          <template v-for="(item, itemIndex) in userList">
            <v-divider
                v-if="itemIndex"
                :key="itemIndex + 'divider'"
            ></v-divider>
            <v-list-item
                :key="itemIndex"
                class="px-5"
                @click="loginUser(item)"
            >
              <v-list-item-content>
                <v-list-item-title class="fz-14 font-weight-semi-bold">
                  <span class="py-4 pr-4">{{ item.acco_name }}</span>
                </v-list-item-title>

                <v-list-item-subtitle class="fz-14">
                  <span>{{ item.web_url }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </template>
        </v-list>

        <v-card-actions class="py-5 justify-center">
          <v-btn
              color="secondary"
              outlined
              class="mx-2 px-4 text-capitalize"
              @click="backToLogin"
          >
            Back
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <v-overlay :value="loadingSelectedAccount">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <vue-snotify/>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Role } from '@/_helpers/role'
import { isWhitelabel } from '@/utilities/api'

export default {
  name: 'Login',
  data: () => ({
    submitted: false,
    loading: false,
    error: '',
    form: {
      email: '',
      password: '',
      rememberMe: false
    },
    formValidate: false,
    emailRules: [
      v => !!v || 'E-mail is required'
    ],
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    autofilled: false,
    showPassword: false,
    isSelectAccount: false,
    loadingSelectedAccount: false
  }),
  mounted () {
    this.$nextTick(() => {
      this.testAutoFill()
    })
  },
  methods: {
    ...mapActions(['login', 'getAccountData', 'getAccountUserData', 'setCurrentUser', 'reSendConfirmation', 'setAccounts']),
    backToLogin () {
      this.setAccounts([])
      this.isSelectAccount = false
    },
    async loginUser (user = null) {
      try {
        this.submitted = true

        if (!this.formValidate) return

        this.loading = true
        const form = {
          email: this.form.email,
          password: this.form.password
        }
        if (user) {
          form.account_uuid = user.acco_uuid
          this.loadingSelectedAccount = true
        }
        const { data } = await this.login(form)
        if (!data.user) {
          this.setAccounts(data)
          this.isSelectAccount = true
          return
        }

        localStorage.removeItem('email')
        localStorage.removeItem('password')

        // INFO: subdomain users will work only with the corresponding subdomain
        if (user) {
          const query = {
            token: data.user.token,
            role: data.user.role
          }
          localStorage.clear()
          const _query = encodeURIComponent(btoa(JSON.stringify(query)))
          window.location.replace('https://' + user.web_url + `/#/login?user=${_query}`)
          return
        }

        if (this.form.rememberMe) {
          localStorage.setItem('email', this.form.email)
          localStorage.setItem('password', this.form.password)
        }

        if (data.user.role === Role.SuperAdmin) return this.$router.push({ name: 'AdminCompany' })

        await this.getAccountData(data.user.role)

        this.$snotify.notifications = []
        this.$snotify.success('Successful')

        setTimeout(() => {
          this.$router.push({ name: 'Home' })
        }, 2000)
      } catch (err) {
        this.loadingSelectedAccount = false
        let errorMessage = err.data.message

        this.$snotify.notifications = []
        if (errorMessage.includes('confirm')) {
          this.reSend()
          /* const options = {
            timeout: 0,
            buttons: [
              {
                text: 'Re-send confirmation email',
                action: (toast) => {
                  this.reSend()
                  this.$snotify.remove(toast.id)
                }
              }
            ]
          }
          this.$snotify.error(errorMessage, options) */
          return
        }

        if (err.data.statusCode === 404 || errorMessage === 'Unauthorized') {
          errorMessage = 'Invalid Username and Password'
        }
        if (errorMessage.includes('must be an email')) {
          errorMessage = 'Invalid email'
        }
        this.$snotify.error(errorMessage)
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async reSend () {
      try {
        const { data } = await this.reSendConfirmation(this.form.email)
        if (!data) return
        this.$snotify.notifications = []
        this.$snotify.success('Confirmation message sent, please check email.')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.data.message)
        throw new Error(err)
      }
    },
    rememberPass () {
      const email = localStorage.getItem('email')

      if (email) {
        this.form.email = email
      }

      const password = localStorage.getItem('password')

      if (password) {
        this.form.password = password
      }

      if (email && password) {
        this.form.rememberMe = true
      }
    },
    detectAutofill (element) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button')
        }, 600)
      })
    },
    async testAutoFill () {
      if (this.loadingSelectedAccount) return
      this.autofilled = await this.detectAutofill(document.querySelector('.v-text-field__slot input'))
    },
    focusPassword () {
      if (this.form.password) return this.loginUser()
      this.$refs.password.focus()
    },
    async getAccountDataByUser (user) {
      try {
        const decodedUser = JSON.parse(atob(decodeURIComponent(user)))
        this.loadingSelectedAccount = true
        this.setCurrentUser(decodedUser)
        await this.getAccountUserData(decodedUser)
        if (['account_admin'].includes(decodedUser.role)) {
          await this.getAccountData(decodedUser.role)
        }
        this.$snotify.notifications = []

        this.$router.push({ name: 'Home' })
      } catch (err) {
        this.loadingSelectedAccount = false
        let errorMessage = err.data.message

        this.$snotify.notifications = []
        if (err.data.statusCode === 404) {
          errorMessage = 'Wrong credentials have been provided'
        }
        this.$snotify.error(errorMessage)
        throw new Error(err)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getEmail',
      'getPassword'
    ]),
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    userList () {
      return this.$store.state.accounts.accounts
    }
  },
  created () {
    const hostname = window.location.hostname
    const isBaseDomain = !hostname.includes('portal.') && !hostname.includes('testportal.') && !hostname.includes('test.') && hostname !== 'localhost'
    if (isBaseDomain) {
      window.open(isWhitelabel ? `https://${hostname}/#/login` : 'https://portal.fonio.app/#/login', '_self')
      return
    }
    this.rememberPass()
    const user = this.$route.query.user
    if (user) {
      this.getAccountDataByUser(user)
    }
    // redirect to home if already logged in
    // if (this.currentUser) {
    //   return this.$router.push('/')
    // }

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.$route.query.returnUrl || '/'
  }
}
</script>

<style scoped>
.mobile-login ::v-deep .login-field .v-input__slot .v-text-field__slot{
  height: 48px;
}
::v-deep .v-messages,
.login-field ::v-deep .v-text-field__details {
  display: none;
}
.text-decoration-none:hover span {
  color: #5c6ac4 !important;
}
</style>
