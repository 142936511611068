<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" class="">
        <v-row class="ma-0 gap-10">
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Company Name</label>
            <v-text-field
              v-model="filter.accountName"
              outlined
              solo
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Status</label>
            <v-select
              v-model="filter.status"
              :items="statusOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              class="pa-0"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="px-0 grey-button"
          outlined
          @click="exportCurrentView"
        >
          Export
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="itemsPerPage"
          item-key="id"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.is_whitelabel`]="{ value }">
            {{ value ? 'Yes' : 'No' }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.status,
                'red-lighten': !item.status
              }"
              small
              class="text-capitalize"
            >
            <span v-if="item.status">Active</span>
            <span v-else>Inactive</span>
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminPayment', params: { companyUuid: item.accountUuid } })"
                  >
                    <v-icon color="#919EAB">payments</v-icon>
                  </v-btn>
                </template>
                <span>View Payment</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminInvoice', params: { companyUuid: item.accountUuid } })"
                  >
                    <v-icon color="#919EAB">payment</v-icon>
                  </v-btn>
                </template>
                <span>View Invoice</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminNumber', params: { companyUuid: item.accountUuid } })"
                  >
                    <v-icon color="#919EAB">sim_card</v-icon>
                  </v-btn>
                </template>
                <span>View DIDs</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminUsers', params: { companyUuid: item.accountUuid } })"
                  >
                    <v-icon color="#919EAB">person</v-icon>
                  </v-btn>
                </template>
                <span>View Users</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleStatus(item)"
                  >
                    <v-icon color="#919EAB">{{ item.status ? 'toggle_on' : 'toggle_off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.status ? 'Deactivate' : 'Activate' }}</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openCustomDomainDialog(item)"
                  >
                    <v-icon color="#919EAB">domain_add</v-icon>
                  </v-btn>
                </template>
                <span>Set Custom Domain</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleWhitelabel(item)"
                  >
                    <v-icon color="#919EAB">{{ item.is_whitelabel ? 'toggle_on' : 'toggle_off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_whitelabel ? 'Remove whitelabel' : 'Set whitelabel' }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-divider/>
        <div v-if="total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
            <v-btn
              :class="{ 'grey-button--selected': page === 1 }"
              text
              tile
              min-width="56"
              @click="pagination(1)"
            >1
            </v-btn>
            <span v-if="page > 3">...</span>
            <template v-for="index in (countPage - 2)">
              <template v-if="index >= page - 2 && index <= page">
                <v-divider
                  :key="index + 'v-divider--page'"
                  vertical
                  class="pa-0"
                />
                <v-btn
                  :class="{'grey-button--selected': index + 1 === page}"
                  text
                  tile
                  min-width="56"
                  :key="index"
                  @click="pagination(index + 1)"
                >{{ index + 1 }}
                </v-btn>
              </template>
            </template>
            <span v-if="page < countPage - 2">...</span>
            <v-btn
              :class="{ 'grey-button--selected': page === countPage }"
              text
              tile
              min-width="56"
              @click="pagination(countPage)"
            >{{ countPage }}
            </v-btn>
          </div>

          <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="customDomainDialog.show"
      :max-width="$vuetify.breakpoint.mdAndUp ? '600' : '100%'"
    >
      <v-card>
        <v-card-title class="font-weight-bold"> Set Custom Domain </v-card-title>

        <v-row class="ma-0 gap-10">
          <v-col cols="12" class="d-flex flex-column flex-md-row align-md-center justify-space-between py-0">
            <label>Custom Domain</label>
            <v-text-field
              v-model="customDomainDialog.customDomain"
              outlined
              solo
              class="muted--text custom-domain"
              light
              color="muted"
              label="Enter Custom Domain"
            />
          </v-col>
        </v-row>

        <v-card-actions class="pb-5 pt-5 justify-md-end">

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
            <v-btn
              color="secondary"
              outlined
              class="w-100 text-capitalize"
              @click="closeCustomDomainDialog"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
            <v-btn
              depressed
              color="primary"
              @click="saveCustomDomain"
              class="w-100 text-capitalize"
            >
              Save
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import exportToCsv from '@/utilities/exportCSV'

export default {
  name: 'Company',
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: 'Registered On',
        align: 'start',
        sortable: false,
        value: 'createdOn',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: false,
        value: 'accountName',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Subdomain',
        align: 'start',
        sortable: false,
        value: 'web_url',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: '# of DIDs',
        align: 'start',
        sortable: false,
        value: 'did_count',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      /* {
        text: 'Plan',
        align: 'start',
        sortable: false,
        value: 'plan',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }, */
      {
        text: 'Custom Domain',
        align: 'start',
        sortable: false,
        value: 'custom_domain',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Whitelabel',
        align: 'start',
        sortable: false,
        value: 'is_whitelabel',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    total: 0,
    filter: {
      accountName: '',
      status: ''
    },
    statusOptions: [
      {
        label: 'Not selected',
        value: ''
      },
      {
        label: 'Active',
        value: 'true'
      },
      {
        label: 'Inactive',
        value: 'false'
      }
    ],
    customDomainDialog: {
      show: false,
      uuid: '',
      customDomain: ''
    }
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    }
  },
  watch: {
    page () {
      this.loadCompany()
    },
    filter: {
      deep: true,
      handler () {
        this.page = 1
        this.loadCompany()
      }
    }
  },
  created () {
    this.loadCompany()
  },
  methods: {
    ...mapActions('admin', ['getAdminCompany', 'changeAdminStatus', 'changeAdminWhitelabel', 'setAdminCustomDomain']),
    ...mapActions(['isSubdomainAvailable']),
    async loadCompany (forExport = false) {
      try {
        this.loading = true
        const params = {
          skip: forExport ? 0 : (this.page - 1) * this.itemsPerPage,
          limit: forExport ? 9999 : this.itemsPerPage
        }

        Object.entries(this.filter).forEach(([key, value]) => {
          if (value) params[key] = value
        })

        const { data } = await this.getAdminCompany(params)
        if (!data) return
        if (forExport) return data.data
        this.items = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async toggleStatus (item) {
      this.$snotify.notifications = []
      try {
        const { data } = await this.changeAdminStatus({ uuid: item.accountUuid, status: !item.status })
        if (!data) return
        this.$snotify.success('Successful')
        item.status = !item.status
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    openCustomDomainDialog (item) {
      this.customDomainDialog = {
        show: true,
        uuid: item.accountUuid,
        customDomain: item.custom_domain
      }
    },
    closeCustomDomainDialog () {
      this.customDomainDialog = {
        show: false,
        uuid: '',
        customDomain: ''
      }
    },
    async saveCustomDomain () {
      this.$snotify.notifications = []
      try {
        const { data: { isAvailable } } = await this.isSubdomainAvailable(this.customDomainDialog.customDomain)
        if (!isAvailable) return this.$snotify.error('This subdomain has been taken. Please enter another one.')

        const { data } = await this.setAdminCustomDomain(this.customDomainDialog)
        if (!data) return
        this.$snotify.success('Successful')
        this.closeCustomDomainDialog()
        this.loadCompany()
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    async toggleWhitelabel (item) {
      this.$snotify.notifications = []
      try {
        const { data } = await this.changeAdminWhitelabel({ uuid: item.accountUuid, whitelabel: !item.is_whitelabel })
        if (!data) return
        this.$snotify.success('Successful')
        item.is_whitelabel = !item.is_whitelabel
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    async exportCurrentView () {
      const csvArray = []
      let values = []
      const self = this

      this.headers.forEach(function (item, i) {
        if (item.value !== 'action') {
          values.push(item.text)
        }
      })
      csvArray.push(values)
      const items = await this.loadCompany(true)
      items.forEach(function (item, i) {
        values = []
        self.headers.forEach(function (it, i) {
          if (it.value !== 'action') {
            if (Object.keys(it).includes('formatter')) {
              const val = self.formatDate(item[it.value])
              values.push(val)
            } else {
              values.push(item[it.value])
            }
          }
        })
        csvArray.push(values)
      })

      exportToCsv('Company.csv', csvArray)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-domain {
  margin-left: 10px;
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__control {
    min-height: unset !important;
    .v-input__slot {
      margin: 0 !important;
    }
  }
}
</style>
