<template>
  <div class="pt-4 pb-2">
    <a
      @click="$router.back()"
      class="back-link px-3 px-md-0"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Mailboxes
    </a>
    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 px-4 px-md-0">{{ callbackView ? 'Callback mailbox' : mailboxTitle}}</h1>
    <v-row class="mb-2 ma-0">
      <v-col
        cols="12"
        md="4"
        class="py-2"
      >
        <v-text-field
          v-model="filter.search"
          label="Search by contact or phone number"
          type="text"
          class="mt-md-6"
          style="height:36px"
          solo
          outlined
          dense
          hide-details
        >
          <template v-slot:prepend-inner>
            <v-icon size="20px" color="#919EAB">search</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-2"
      >
        <label>Caller ID</label>
        <v-autocomplete
          v-model="filter.callerId"
          :items="contactList"
          outlined
          item-text="label"
          item-value="value"
          solo
          class="muted--text"
          light
          color="muted"
          label="Caller ID"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-2"
      >
        <label>Callee</label>
        <v-autocomplete
          v-model="filter.callee"
          :items="numberList"
          outlined
          item-text="label"
          item-value="value"
          solo
          class="muted--text"
          light
          color="muted"
          label="Callee"
        />
      </v-col>
    </v-row>
    <v-card :loading="loading" class="mx-3">
      <v-list class="pa-0" v-if="voicemails.length">
        <voicemail-component :voicemails="voicemails" />

        <v-divider/>

        <div v-if="countPage > 1" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>
      <v-list v-else class="d-flex align-center justify-center pa-4">
        <span v-if="!loading">No voicemails</span>
      </v-list>
    </v-card>
    <!-- <div>
      <mail-history />
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VoicemailComponent from '@/components/VoicemailComponent'
import moment from 'moment'

export default {
  name: 'MarketingMailbox',
  components: {
    VoicemailComponent
  },
  data: () => ({
    loading: false,
    take: 10,
    page: 1,
    voicemails: [],
    total: 0,
    contactList: [
      { label: 'Not selected', value: '' }
    ],
    numberList: [
      { label: 'Not selected', value: '' }
    ],
    filter: {
      search: '',
      callerId: '',
      callee: ''
    }
  }),
  computed: {
    paginationList () {
      return this.mailboxes.slice((this.page - 1) * this.itemsPerPage, this.itemsPerPage * this.page)
    },
    countPage () {
      return Math.ceil(this.total / this.take)
    },
    callbackView () {
      return this.$route.path.includes('callback')
    },
    mailboxTitle () {
      return this.$route.params.boxName
    },
    ...mapState('components', [
      'callbackRequested'
    ]),
    callbackRequestedPopUp: {
      get () {
        console.log('get', this.callbackRequested)
        return this.callbackRequested
      },
      set (value) {
        console.log('set', value)
        return value
      }
    }
  },
  watch: {
    selectedAction (actionName, newAction) {
      this.$store.dispatch(`components/${actionName}`, newAction)
    },
    page () {
      this.loadVoicemails()
    },
    filter: {
      deep: true,
      handler () {
        this.loadVoicemails()
      }
    }
  },
  created () {
    this.loadVoicemails()
    this.getContactList()
    this.getNumberList()
  },
  methods: {
    ...mapActions(['getVoicemails', 'getContacts', 'didNumbersList']),
    async loadVoicemails () {
      if (!this.$route.params.uuid) return
      try {
        this.loading = true

        const filter = {
          orderType: 'DESC',
          orderBy: 'created_at',
          skip: (this.page - 1) * this.take,
          take: this.take,
          startDate: '2001-01-01',
          endDate: moment(new Date()).format('YYYY-MM-DD'),
          mailbox_uuid: this.$route.params.uuid
        }
        if (this.filter.callerId) filter.caller_id = this.filter.callerId
        if (this.filter.callee) filter.phone_number = this.filter.callee

        if (this.filter.search) {
          if (isNaN(this.filter.search)) {
            const search = this.filter.search.split(' ')
            filter.caller_first_name = search[0]
            if (search.length > 1) filter.caller_last_name = search[1]
          } else {
            filter.caller_id = this.filter.search
          }
        }

        const { data } = await this.getVoicemails(filter)

        this.voicemails = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async getContactList () {
      try {
        const { data } = await this.getContacts({ take: 9999 })
        if (!data) return
        const contactList = data.contactList.map(item => ({
          label: `${item.firstName || ''} ${item.lastName || ''}`,
          value: parseInt(item.phoneNumber)
        }))
        this.contactList.push(...contactList)
      } catch (err) {
        throw new Error(err)
      }
    },
    async getNumberList () {
      try {
        const { data } = await this.didNumbersList({})
        if (!data) return
        const numberList = data.didList.map(item => ({
          label: item.didNumber,
          value: item.didNumber
        }))
        this.numberList.push(...numberList)
      } catch (err) {
        throw new Error(err)
      }
    },
    userInformation () {
      this.$store.dispatch('components/ContactDetails', !this.$store.state.components.contactDetails)
      this.$store.dispatch('components/CallbackRequestedMailbox', false)
      this.$store.dispatch('components/PlayMessage', false)
    },
    send () {
      this.$store.dispatch('components/ContactDetails', false)
      this.$store.dispatch('components/CallbackRequestedMailbox', !this.$store.state.components.callbackRequestedMailbox)
      this.$store.dispatch('components/PlayMessage', false)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page !== 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
